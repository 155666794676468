@tailwind base;
@tailwind components;
@tailwind utilities;

/*
//
//
// Fonts
//
//
*/

@font-face {
  font-family: 'PrimaryFont';
  src: url('/fonts/PPMonumentExtended-Book.woff2') format('woff2'),
       url('/fonts/PPMonumentExtended-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeadingFont';
  src: url('/fonts/PPMonumentExtended-BoldItalic.woff2') format('woff2'),
       url('/fonts/PPMonumentExtended-BoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SubHeadingFont';
  src: url('/fonts/PPMonumentExtended-Regular.woff2') format('woff2'),
       url('/fonts/PPMonumentExtended-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  scroll-padding-top: 100px;
}

body {
  @apply font-primary;
}

h1, h2, h3 {
  @apply font-heading;
}

h4, h5, h6 {
  @apply font-subHeding;
}


p {
  font-size: 0.9rem;
  @apply font-primary;
}

h1{
  font-size: 70px;
}

h2{
  font-size: 60px;
}
h3{
  font-size: 1.2rem;
}


/* Medium Devices (Tablets)  */
@media screen and (max-width: 991px) {
  h1 {
    font-size: 3.2rem;
  }
  h2 {
    font-size: 3.0rem;
  }
}

/* Small Devices (Phones)  */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.4rem;
  }
  h2 {
    font-size: 2.3rem;
  }
}

/* Extra small Devices (Phones)  */
@media screen and (max-width: 479px) {
  h1 {
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}

/*
//
//
// Navbar
//
//
*/

.navbar {
  background-color: rgba(52, 47, 45, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; /* p-4 equivalent */
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.navbar-links{
  display: flex;
  gap: 1rem; /* space-x-4 equivalent */
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: 'subHeadingFont';
  padding-right: 20px;
  padding-left: 100px;
}

.navbar-link {
  color:#e4ded7;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
}

.navbar-link:hover {
  color: #00fff0; /* hover:text-gray-700 equivalent */
}

.navbar-logo{
  text-decoration: none;
  cursor: pointer;
}

.button {
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #e4ded7;;
  color: #342F2D;
  font-size: 15px;
}

.button:hover {
  background-image: linear-gradient(to top, #e4ded7 0%, #00fff0 100%);
  transition: all 0.3s ease;
}

/* Mobile Menu Styles */
.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: #e4ded7;
  margin: 4px 0;
  transition: all 0.3s ease;
}

/* Hamburger Transformation */
.is-active .bar:nth-child(2) {
  opacity: 0;
}

.is-active .bar:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.is-active .bar:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

/* Mobile Menu Behavior */
@media screen and (max-width: 991px) {


  .navbar-toggle {
    display: flex;
    padding-right: 20px;
  }

  .navbar-links{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem; /* space-x-4 equivalent */
    height: 0;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    font-family: 'subHeadingFont';
    z-index: 999;
    top:75px;
    left: 0;
    overflow: hidden;
    background-color: rgba(52, 47, 45, 0.95);
    z-index: 999;
    opacity: 0;
    padding-bottom: 25px;
    padding-top: 25px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .menu-open {
    height: 300px;
    opacity: 1;
    transition: height 0.3s ease, opacity 0.3s ease;
  }

}
/*
//
//
// Landing Section 
//
//
*/

.landing-section {
  position: relative;
  background-image: url('/images/landing.jpeg');
  background-size: 1500px 1400px;
  background-repeat: no-repeat; 
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-color: #050505;
}

.landing-overlay {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-title {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .landing-section{
    background-size: 1000px 1000px;
    height: 100vh;
  }
}

/*
//
//
// Gallery Section 
//
//
*/

.image-track {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  gap: 4vmin;
  background: #342f2d;
  padding: 50px 0px;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  /* left: 50%; transform: translate(0%, 0%); */
}

.image-track::-webkit-scrollbar {
  display: none;
}
.imageTrack {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;     /* Firefox */
}

.imageTrack-pic{
  width: 300px;
  height: 350px;
  object-fit: cover;
  background: #111;
  user-select: none;
  -webkit-touch-callout: none;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8), 
  15px 8px 30px rgba(0, 0, 0, 0.60);
}


/*
//
//
// Services Section 
//
//
*/

.service-section-title{
  text-align: center;
  color: #e4ded7;
}

.service-div{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  gap: 16px;
  padding: 40px 30px 30px 30px;
}

.service-box{
  position: relative;
  width: 300px;
  height: 350px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #000;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.85;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8), 
  15px 8px 30px rgba(0, 0, 0, 0.60);
}

.service-list{
  text-align: left;
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 30px;
}

.service-list li {
  margin-bottom: 5px;
  font-size: 13px;
}

.service-title{
  padding-bottom: 5px;
}
.service-text{
  padding-top: 30px;
  font-size: 13px;
}

.price{
  position: absolute; 
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.service-link{
  display: flex;
  margin-top: 15px;
  gap: 4px;
  font-size: '1.1rem';
  cursor: pointer;
  background-color: transparent;
  color: #e4ded7;
  align-items: center;
  
}

.service-link-text {
  font-size: 16px;
}

.service-link-open{
  border-top-left-radius: 10px;  /* Top-left corner */
  border-top-right-radius: 10px;
}

.service-link:hover{
  letter-spacing: +2px;

}

.service-dropdown{
  display: none;
  background-color: transparent;
  transition: all 0.3s ease;
  padding: 15px;
}

.service-dropdown-open{
  display: block;
  background-color: rgba(228, 222, 215, 0.85);
  color: #342f2d;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8), 
  15px 8px 30px rgba(0, 0, 0, 0.60);
  transition: all 0.3s ease;
}

.service-dropdown-title{
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.divider {
  position: relative;
  width: 100%; /* Adjust width as needed */
  height: 1px; /* Height of the divider */
  background: transparent; /* Transparent background to show the pseudo-element */
  margin: 30px 0; /* Margin to space the divider from other content */
}

.divider::before {
  content: ""; /* Required to display the pseudo-element */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Full width of the parent (.divider) */
  height: 1px; /* Same height as the parent */
  background: linear-gradient(to right, rgba(52, 47, 45, 0) 0%,#342f2d 50%, rgba(52, 47, 45, 0) 100%); /* Gradient effect */
}

.sevice-p {
  font-size: 11px;
}


@media screen and (max-width: 991px) {

  .service-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 54px;
  }
}

@media screen and (max-width: 767px) {
  .service-link-text{
    font-size: '11px';
  }
}

.cover-image{
  box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.4);

}

/*
//
//
// Form
//
//
*/

.formSection{
  background-color: #342F2D;
}

.formContainer{
  background-color: #E4DED7;
  opacity: 93%;
  border-radius: 10px;
  border-color: #000;
}

.form-heading {
  font-size: 3.8rem;
  line-height: 65px;
  padding-top: 20px;
  padding-bottom: 15px;
  color: #342F2D;
}

.form-image-text {
  font-size: 0.5rem;
}

/* Small Devices (Phones)  */
@media screen and (max-width: 767px) {
  .form-heading{
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 410px) {
  .form-heading{
    font-size: 2rem;
  }
}
/*
//
//
// About
//
//
*/
.about-container{
  width: 350px;
  background: 'bg-beige';
  user-select: none;
  -webkit-touch-callout: none;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8), 
  15px 8px 30px rgba(0, 0, 0, 0.60);
}

.about-section{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 96px;
}

@media screen and (max-width: 967px) {
  .about-section{
    flex-direction: column;
  }

  .about-textBox{
    width: 300px;
    font-size: 11px;
  }
}

@media screen and (max-width: 400px) {
  .about-container{
    width: 300px;
  }

  .about-textBox{
    width: 300px;
    font-size: 11px;
  }
}

.about-textBox{
  font-size: 14px;
  width: 300px;
}


/*
//
//
// Footer
//
//
*/

.footer-container{
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  max-width: 100vw;
  background-color: #e4ded7;
}

.social{
  justify-content: center;
  align-items: center;
  text-align: center;
}

.social-icons{
  font-size: 80px;
  display: flex;
  justify-content: space-evenly;
}

.info-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

@media (max-width: 967px) {
  .footer-container {
    grid-template-columns: 1fr; /* Single column layout */
    grid-template-rows: auto auto; /* Rows will stack vertically */
    justify-items: center; /* Centers items horizontally */
    gap: 10px; /* Optional: Add some gap between the rows */
    width: 100%;
  }
  .social-icons{
    justify-content: space-between;
    gap: 50px;
  }
  .info-container {
    align-items: center;
    text-align: center;
  }
  .info-title {
    padding-bottom: 10px;
  }
}